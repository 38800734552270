import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import Head from '../components/head'
import styles from '../styles/about.module.scss'

export default ({ data }) => {
  return (
    <Layout>
      <Head title="Submission Guidelines" />
      <div className={styles.wrapper}>
        <div className={styles.title}>
          <h1>Submission Guidelines</h1>
        </div>
        <div
          className={styles.content}
          dangerouslySetInnerHTML={{ __html: data.allMarkdownRemark.edges[0].node.html }}
        />
      </div>
    </Layout>
  )
}
export const query = graphql`
  query {
    allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/(guidelines)/" } }
      sort: { fields: frontmatter___editors___name, order: ASC }
    ) {
      edges {
        node {
          frontmatter {
            editors {
              description
              name
            }
          }
          html
        }
      }
    }
  }
`
